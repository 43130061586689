if (!process.env.APPSYNC_API_URL) {
  console.warn(
    "Ensure appsync settings are specified within .env. These come from running sls deploy"
  );
}

const config = {
  aws_appsync_graphqlEndpoint: process.env.APPSYNC_API_URL,
  aws_appsync_region: "us-west-2",
  aws_appsync_authenticationType: "API_KEY",
  aws_appsync_apiKey: process.env.APPSYNC_API_KEY
};

export default config;
